














import { Vue, Component } from "vue-property-decorator";
import Organize from "@/components/home/Organize.vue";

@Component({
    components: {
        Organize
    }
})
export default class ProjectBenefit extends Vue {
    get img_src(){
        const base_src="https://ittn.oss-cn-beijing.aliyuncs.com/2021baiqiang/"
        return this.$i18n.locale=="en"?`${base_src}bestech_projectbenefit_en.png`:`${base_src}bestech_projectbenefit_zh.png`
    }
}
